import React from "react"

import "./styles.scss"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Overview from "../components/Overview"
import MarkdownContent from "../components/MarkdownContent"

import { graphql } from "gatsby"

const IndexPage = ({ data, location }) => {
  return (
    <Layout>
      <SEO
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description}
        pathname={location.pathname}
      />
      <Overview context={{}} items={data.allStoreListing.edges}>
        <MarkdownContent mdx={data.mdx} />
      </Overview>
    </Layout>
  )
}

export const query = graphql`
  query listings {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    allStoreListing(
      filter: { featured_rank: { ne: null } }
      sort: { fields: featured_rank }
    ) {
      edges {
        node {
          id
          title
          fields {
            slug
          }
          images {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }

    mdx(fields: { slug: { eq: "/default/default" } }) {
      body
      frontmatter {
        title
        description
      }
    }
  }
`

export default IndexPage
